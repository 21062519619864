// client/src/App.js
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useNavigate , Navigate,useLocation} from 'react-router-dom';
import { Layout, Menu } from '@arco-design/web-react';
import { IconUser, IconApps, IconQrcode } from '@arco-design/web-react/icon';
import '@arco-design/web-react/dist/css/arco.css';

import LoginPage from './pages/LoginPage';
import ProductManagementPage from './pages/ProductManagementPage';
import RedeemCodeManagementPage from './pages/RedeemCodeManagementPage';
import RedeemProductPage from './pages/RedeemProductPage';


import withAuth from './hoc/withAuth'; // 如果你将 withAuth.js 放在 client/src/hoc/ 目录下




const MenuItem = Menu.Item;
const SubMenu = Menu.SubMenu;
const Sider = Layout.Sider;
const Content = Layout.Content;
const collapsedWidth = 60;
const normalWidth = 220;

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [siderWidth, setSiderWidth] = useState(normalWidth);

  const [isAuthenticated, setIsAuthenticated] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    setIsAuthenticated(!!token);
  }, []);

  const onCollapse = (collapsed) => {
    setCollapsed(collapsed);
    setSiderWidth(collapsed ? collapsedWidth : normalWidth);
  };

  const handleMoving = (_, { width }) => {
    if (width > collapsedWidth) {
      setSiderWidth(width);
      setCollapsed(!(width > collapsedWidth + 20));
    } else {
      setSiderWidth(collapsedWidth);
      setCollapsed(true);
    }
  };

  return (
    <Router>
      <Layout style={{ minHeight: '100vh' }}>
        <Routes>
          <Route path="/get-hoho-product/:redeemcode" element={<RedeemProductPage />} />
          <Route path="/get-hoho-product" element={<RedeemProductPage />} />
          <Route path="/admin/*" element={
            <>
              <Layout>

                <Sider
                  collapsible
                  onCollapse={onCollapse}
                  collapsed={collapsed}
                  width={siderWidth}
                  resizeBoxProps={{
                    directions: ['right'],
                    onMoving: handleMoving,
                  }}
                >
                  <NavigationMenu />
                </Sider>
                <Content
                  style={{ background: 'rgb(250,250,250)', padding: '30px' }}
                >
                  <Routes>
                    <Route path="login" element={<LoginPage />} />
                    {isAuthenticated ? (
                      <>
                        <Route path="product-management" element={<ProductManagementPage />} />
                        <Route path="redeem-management" element={<RedeemCodeManagementPage />} />
                        {/* 定义其他需要验证的路由 */}
                      </>
                    ) : (
                      <Route path="*" element={<Navigate to="/login" replace />} />
                    )}
                    {/* <Route path="/login" element={<LoginPage />} />
                    <Route path="/product-management" element={withAuth(ProductManagementPage)} />
                    <Route path="/redeem-management" element={withAuth(RedeemCodeManagementPage)} />
                    定义其他路由 */}
                  </Routes>
                </Content>
              </Layout>
            </>
          } />
          <Route path="*" element={<Navigate to="/get-hoho-product" replace />} />
        </Routes>
      </Layout>
    </Router>
  );
}

const NavigationMenu = () => {
  const navigate = useNavigate();
  const location = useLocation();  // 获取当前位置
  // 根据当前路由设置默认选中的菜单项
  const getDefaultSelectedKeys = () => {
    switch (location.pathname) {
      case '/admin/login':
        return ['1'];
      case '/admin/product-management':
        return ['2'];
      case '/admin/redeem-management':
        return ['3'];
      default:
        return [];
    }
  };

  return (
    <Menu
      autoOpen
      style={{ width: '100%' }}
      defaultSelectedKeys={getDefaultSelectedKeys()} // 使用函数设置默认选中项

    >
      <MenuItem key="1" onClick={() => navigate('/admin/login')}>
        <IconUser />登录
      </MenuItem>
      <MenuItem key="2" onClick={() => navigate('/admin/product-management')}>
        <IconApps />产品管理
      </MenuItem>
      <MenuItem key="3" onClick={() => navigate('/admin/redeem-management')}>
        <IconQrcode />兑换码管理
      </MenuItem>
      {/* 在这里添加更多的导航项 */}
    </Menu>
  );
};

export default App;
