// client/src/pages/ProductManagementPage.jsx

import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Message, Modal, Input, Upload, Space, Typography } from '@arco-design/web-react';
import { IconFolder, IconDriveFile } from '@arco-design/web-react/icon';

const { Text } = Typography;
// client/src/pages/ProductManagementPage.jsx
const apiUrl = '/api/products'; // API 基础路径

function ProductManagementPage() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [newName, setNewName] = useState('');
    const [currentProduct, setCurrentProduct] = useState(null);
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [currentFolderId, setCurrentFolderId] = useState(null); // 当前文件夹ID

    const uploadRef = useRef();

    // 获取商品列表
    const fetchProducts = async (folderId = currentFolderId) => {
        console.log(`开始获取商品列表，当前文件夹ID: ${folderId}`);
        setIsLoading(true);
        try {
            const url = folderId ? `${apiUrl}/${folderId}/contents` : `${apiUrl}/product-root-folder/contents`;
            console.log(`请求URL: ${url}`);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            console.log('请求返回的数据:', data);

            if (data.status === 'success') {
                console.log('商品列表加载成功', data.contents);
                setProducts(data.contents);
            } else {
                console.error('获取商品列表出错:', data.message);
                Message.error(data.message);
            }
        } catch (error) {
            console.error('获取商品列表失败:', error);
            Message.error('获取商品列表失败');
        }
        setIsLoading(false);
    };

    useEffect(() => {
        fetchProducts();
    }, [currentFolderId]);

    // 删除商品或文件夹
    const handleDelete = async (itemId, is_folder) => {
        try {
            const response = await fetch(`${apiUrl}/${itemId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            if (data.status === 'success') {
                Message.success(`${is_folder ? '文件夹' : '商品'}删除成功`);
                fetchProducts(); // 刷新列表
            } else {
                Message.error(data.message);
            }
        } catch (error) {
            console.error('删除操作失败:', error);
            Message.error('删除操作失败');
        }
    };

    // 显示重命名对话框
    const showRenameModal = (product) => {
        setCurrentProduct(product);
        setNewName(product.name);
        setIsModalVisible(true);
    };

    // 处理重命名
    const handleRename = async () => {
        if (!currentProduct || !newName) return;
        try {
            const response = await fetch(`${apiUrl}/${currentProduct.id}`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ newName }),
                
            });
            const data = await response.json();
            if (data.status === 'success') {
                Message.success('重命名成功');
                setIsModalVisible(false);
                fetchProducts(); // 刷新列表
            } else {
                Message.error(data.message);
            }
        } catch (error) {
            console.error('重命名操作失败:', error);
            Message.error('重命名操作失败');
        }
    };

    // 下载文件或文件夹
    const handleDownload = async (itemId, is_folder) => {
        window.open(`${apiUrl}/${itemId}/download`, '_blank');
    };

    // 进入文件夹
    const enterFolder = (recordId) => {
        console.log("当前记录id：", recordId);
        if (recordId) {
            console.log("进入文件夹:", recordId);
            setCurrentFolderId(recordId);
        } else {
            console.warn("该记录不是一个文件夹:", recordId);
        }
    };

    // 假设 uploadItem 函数已经在上下文中定义
    const uploadItem = async (item, path = '', parentId = currentFolderId) => {
        console.log(`准备上传项目：${item.originFile.name}, 路径：${path}, 父ID：${parentId}`);
        const formData = new FormData();
        formData.append('file', item.originFile, encodeURIComponent(item.originFile.name));
        formData.append('path', path);

        if (parentId) {
            formData.append('parentId', parentId); // 添加当前文件夹ID作为parentId
        }

        try {
            const response = await fetch(`${apiUrl}/upload`, {
                method: 'POST',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
                body: formData,
            });
            const data = await response.json();
            if (data.status === 'success') {
                console.log(`${item.name} 上传成功`);
                console.log(`oringinname: ${item.originFile.name} 上传成功`);
                Message.success(`${item.name} 上传成功`);
                return data; // 返回上传成功后的数据，可能包含文件或文件夹的ID
            } else {
                throw new Error(data.message);
            }
        } catch (error) {
            console.error(`上传失败: ${item.name}`, error);
            Message.error(`${item.name} 上传失败: ${error.message}`);
        }
    };

    const handleUpload = async () => {
        console.log('开始处理上传');
        if (selectedFiles.length === 0) {
            Message.warning('请选择文件或文件夹');
            return;
        }

        console.log('已选择的文件：', selectedFiles);

        let folderList = [];
        selectedFiles.forEach(file => {
            let path = file.originFile.webkitRelativePath || file.originFile.name;
            console.log('处理文件路径：', path);
            let pathParts = path.split('/');
            let combinedPath = currentFolderId ? `${currentFolderId}/${path}` : path;
            for (let i = 0; i < pathParts.length; i++) {
                folderList.push(pathParts.slice(0, i + 1).join('/'));
            }
        });

        console.log('初始文件夹列表：', folderList);

        // 对文件夹进行排序并去重
        folderList = [...new Set(folderList.sort((a, b) => a.split('/').length - b.split('/').length))];

        // 定义一个函数来检查路径是否指向一个文件
        function isFile(path) {
            const lastPart = path.split('/').pop();
            return lastPart.includes('.');
        }

        // 使用 isFile 函数过滤出只包含文件夹的路径
        folderList = folderList.filter(item => !isFile(item));
        console.log('排序、去除文件并去重后的文件夹列表：', folderList);

        // 创建文件夹并获取文件夹ID
        let folderIdList = {};
        for (let folderPath of folderList) {
            console.log('处理文件夹路径：', folderPath);
            const response = await fetch(`/api/products/folder-by-path?path=${encodeURIComponent(folderPath)}`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            if (data && data.folderId) {
                folderIdList[folderPath] = data.folderId.id;
                console.log(`文件夹ID获取成功：${folderPath} => ${data.folderId.id}`);
            } else {
                console.error('无法获取或创建文件夹ID', data);
            }
        }


        console.log('文件夹ID列表：', folderIdList);

        // 上传每个文件
        const uploadItemWithFolder = async (item, folderPath) => {
            console.log(`上传文件：${item.originFile.name}, 路径：${folderPath}`);
            const parentId = folderPath ? folderIdList[folderPath] : null;
            console.log(`父ID：${parentId}`);
            await uploadItem(item, folderPath, parentId);
        };

        for (let item of selectedFiles) {
            let itemPath = item.originFile.webkitRelativePath || '';
            console.log(`处理文件：${item.originFile.name}, 完整路径：${itemPath}`);

            // 获取文件所在的文件夹路径
            let folderPath = itemPath.substring(0, itemPath.lastIndexOf('/'));

            await uploadItemWithFolder(item, folderPath);
        }

        console.log('重置已选择的文件');
        setSelectedFiles([]);
        console.log('刷新产品列表');
        fetchProducts();
    };

    const setProductForSale = async (item) => {
        try {
            const response = await fetch(`${apiUrl}/${item.id}/edit-product-for-sale`, {
                method: 'PUT',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ is_for_sale:!item.is_for_sale }),
                
            });
            const data = await response.json();
            if (data.status === 'success') {
                Message.success('for sale 编辑成功');
                fetchProducts(); // 刷新列表
            } else {
                Message.error(data.message);
            }
        } catch (error) {
            console.error('for sale 编辑失败:', error);
            Message.error('for sale 编辑失败');
        }
    };
    // 处理文件或文件夹的选择变化
    const handleFileChange = (fileList) => {
        console.log("handleFileChange");
        console.log(fileList);
        setSelectedFiles(fileList);
    };


    // 表格列定义，包括进入文件夹和下载的操作
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            render: (text, record) => (
                <>
                    {record.is_folder ? <IconFolder /> : <IconDriveFile />} {decodeURIComponent(text)}
                </>
            ),
        },
        {
            title: '操作',
            render: (_, record) => (
                <Space>
                    <Button onClick={() => setProductForSale(record)}>
                        {record.is_for_sale ? "下架" : "上架"}
                    </Button>
                    {record.is_folder ? (
                        <Button type="primary" onClick={() => enterFolder(record.id)}>
                            进入文件夹
                        </Button>
                    ) : (
                        <Button type="primary" onClick={() => handleDownload(record.id, record.is_folder)}>
                            下载
                        </Button>
                    )}
                    <Button onClick={() => showRenameModal(record)}>
                        重命名
                    </Button>
                    <Button status="danger" onClick={() => handleDelete(record.id, record.is_folder)}>
                        删除
                    </Button>
                </Space>
            ),
        },
    ];

    return (
        <div>
            <Space 
                direction="vertical" 
                size={16} 
                style={{ width: '100%'}}
                >
                <Space>
                    <Button type="primary" onClick={() => fetchProducts()}>
                        刷新列表
                    </Button>
                    <Upload
                        ref={uploadRef}
                        fileList={selectedFiles.length === 0 ? selectedFiles : []}
                        autoUpload={false}
                        onChange={handleFileChange}
                        directory // 允许上传文件夹
                        multiple // 允许多选

                    >
                        <Button type="primary">选择文件夹</Button>
                    </Upload>
                    <Upload
                        ref={uploadRef}
                        fileList={selectedFiles.length === 0 ? selectedFiles : []}
                        autoUpload={false}
                        onChange={handleFileChange}
                        multiple // 允许多选
                    >
                        <Button type="primary">选择文件</Button>
                    </Upload>
                    <Button onClick={handleUpload} disabled={!selectedFiles}>
                        上传产品
                    </Button>
                </Space>
                <Table loading={isLoading} columns={columns} data={products} rowKey="id" />
            </Space>
            <Modal
                title="重命名"
                visible={isModalVisible}
                onOk={handleRename}
                onCancel={() => setIsModalVisible(false)}
            >
                <Input
                    value={newName}
                    onChange={setNewName}
                    placeholder="请输入新名称"
                />
            </Modal>
        </div>
    );
}

export default ProductManagementPage;

