// client/src/pages/RedeemProductPage.jsx
import React, { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { Table, Button, Message, Space, Typography, Divider } from '@arco-design/web-react';
import { IconFolder, IconDriveFile, IconSearch } from '@arco-design/web-react/icon';
import ProductList from '../components/ProductList'; // 引入新的 ProductList 组件

import userPageStyle from './RedeemProductPage.module.css'

function RedeemProductPage() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const { redeemcode } = useParams();  // 从 URL 获取兑换码
    const [currentFolderId, setCurrentFolderId] = useState(null); // 当前文件夹ID
    const [folderHistory, setFolderHistory] = useState([]); // 用于跟踪历史记录的状态
    const [redeemcodeValid, setRedeemcodeValid] = useState(true);
    const [inputCode, setInputCode] = useState('');

    // download progress
    const [downloadProgress, setDownloadProgress] = useState(null);
    const [isDownloading, setIsDownloading] = useState(false);

    // layout management
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
    const [layout, setLayout] = useState('');

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        switch (true) {
            case windowWidth < 600:
                setLayout('small');
                console.log("layout for small screen");
                break;
            case windowWidth < 1000:
                setLayout('medium');
                console.log("layout for med screen");
                break;
            default:
                setLayout('large');
                console.log("layout for large screen");
                break;
        }
    }, [windowWidth]);


    // 获取产品列表
    const fetchProducts = async (folderId = currentFolderId) => {
        console.log(`开始获取商品列表，当前文件夹ID: ${folderId}`);
        setIsLoading(true);
        try {
            console.log(`[fetch] /api/codes/${redeemcode}/folder-id/${folderId}/files-list`);
            const response = await fetch(`/api/codes/${redeemcode}/folder-id/${folderId}/files-list`);
            const data = await response.json();
            if (data.status === 'success') {
                console.log(data.contents);
                setProducts(data.contents);
                setRedeemcodeValid(true);
            } else {
                console.log("fetch error");
                Message.error(data.message);
                setRedeemcodeValid(false);
            }
        } catch (error) {
            console.log("redeem failed");
            Message.error('兑换失败');
            setRedeemcodeValid(false);
        }
        setIsLoading(false);
    };

    useEffect(() => {
        if (redeemcode) {
            setCurrentFolderId(null);  // 重置 currentFolderId
            fetchProducts(null);  // 使用 null 来获取根目录内容
        }
    }, [redeemcode]);

    useEffect(() => {
        fetchProducts();
    }, [currentFolderId]);

    useEffect(() => {
        if (!redeemcodeValid) {
            Message.error('请使用正确的取件码');
        }
    }, [redeemcodeValid]);

    // 处理下载操作
    const handleDownload = async (record) => {

        if (!record) {
            Message.error('无效的下载请求');
            return;
        }
        if (record === "downloadfolder") {
            let folderIdParam = currentFolderId === null ? 'root' : currentFolderId; // 假设后端接受 'root' 作为根目录的标识
            var downloadUrl = `/api/codes/${redeemcode}/distribute/${folderIdParam}`;
        }
        else {
            var downloadUrl = `/api/codes/${redeemcode}/distribute/${record.id}`;
        }


        setIsDownloading(true);
        setDownloadProgress(0);

        try {
            const response = await fetch(downloadUrl);
            if (!response.ok) {
                throw new Error('下载失败');
            }

            const contentLength = response.headers.get('Content-Length');
            const contentDisposition = response.headers.get('Content-Disposition');
            if (!contentLength) {
                throw new Error('无法获取文件大小');
            }

            const reader = response.body.getReader();
            const total = parseInt(contentLength, 10);
            let receivedLength = 0; // 当前接收到的字节数
            const chunks = []; // 存储所有的二进制块

            // Function to process each received chunk
            const processChunk = ({ done, value }) => {
                if (done) {
                    setIsDownloading(false);
                    setDownloadProgress(null);
                    return;
                }
                chunks.push(value);
                receivedLength += value.length;

                const percentComplete = (receivedLength / total) * 100;
                setDownloadProgress(percentComplete);
                // console.log(`Download progress: ${percentComplete.toFixed(2)}%`);

                return reader.read().then(processChunk);
            };

            await reader.read().then(processChunk);

            const blob = new Blob(chunks);
            const downloadLink = document.createElement('a');
            const url = URL.createObjectURL(blob);
            downloadLink.href = url;
            // 如果服务器设置了Content-Disposition，文件名会自动设置，否则使用记录的名称
            // 对文件名进行编码处理
            let decodedFileName = decodeURIComponent(record.name) || 'download';
            if (contentDisposition) {
                console.log(contentDisposition);
                const fileNameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/i);
                console.log(fileNameMatch);
                if (fileNameMatch) {
                    const encodedFileName = fileNameMatch[1];
                    console.log(encodedFileName)
                    // 解码文件名
                    // 检查是否包含 %25 (URL编码的百分号)
                    if (encodedFileName.includes('%25')) {
                        // 二次解码
                        decodedFileName = decodeURIComponent(decodeURIComponent(encodedFileName));
                    } else {
                        // 一次解码
                        decodedFileName = decodeURIComponent(encodedFileName);
                    }
                    console.log(decodedFileName);
                }
            }


            downloadLink.download = decodedFileName;

            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
            URL.revokeObjectURL(url);
        } catch (error) {
            Message.error(error.message);
            setIsDownloading(false);
            setDownloadProgress(null);
        }
    };


    const handleFolderDownload = () => {
        let folderIdParam = currentFolderId === null ? 'root' : currentFolderId; // 假设后端接受 'root' 作为根目录的标识
        let downloadUrl = `/api/codes/${redeemcode}/distribute/${folderIdParam}`;
        console.log("handle folder download")

        setIsDownloading(true);
        setDownloadProgress(0);

        const xhr = new XMLHttpRequest();
        xhr.open('GET', downloadUrl, true);
        xhr.responseType = 'blob';
        xhr.timeout = 8000; // 设置超时时间为5秒


        xhr.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentComplete = (event.loaded / event.total) * 100;
                setDownloadProgress(percentComplete);
                console.log(`Download progress: ${percentComplete.toFixed(2)}%`);
            } else {
                console.log(`Received ${event.loaded} bytes`); // 如果服务器不返回 Content-Length
            }
        };

        xhr.onload = () => {
            console.log(`Status: ${xhr.status}`);
            if (xhr.status === 200) {
                const blob = xhr.response;
                const contentDisposition = xhr.getResponseHeader('Content-Disposition');
                let filename = 'download'; // 默认文件名

                console.log(`Content-Disposition header: ${contentDisposition}`);


                if (contentDisposition) {
                    const filenameMatch = contentDisposition.match(/filename\*=UTF-8''(.+)/i);
                    if (filenameMatch) {
                        const encodedFileName = filenameMatch[1];
                        filename = decodeURIComponent(encodedFileName);
                        console.log(`Decoded filename: ${filename}`);
                    }
                }

                const downloadLink = document.createElement('a');
                const url = URL.createObjectURL(blob);
                downloadLink.href = url;
                downloadLink.download = filename;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
                URL.revokeObjectURL(url);
                console.log("Download succeeded");
            } else {
                Message.error(`下载失败，状态码：${xhr.status}`);
            }
            setIsDownloading(false);
            setDownloadProgress(null);
        };

        xhr.onerror = () => {
            Message.error('下载失败');
            console.log('An error occurred during the download');
            setIsDownloading(false);
            setDownloadProgress(null);
        };

        xhr.send();
        console.log("Request sent");
    };


    // 修改enterFolder以更新历史记录
    const enterFolder = (recordId) => {
        console.log("当前记录id：", recordId);
        if (recordId) {
            setFolderHistory([...folderHistory, currentFolderId]); // 在进入新文件夹前，将当前ID添加到历史记录
            setCurrentFolderId(recordId);
        } else {
            console.warn("该记录不是一个文件夹:", recordId);
        }
    };
    const handleReInputCode = () => {
        // setRedeemcode('');
        setCurrentFolderId(null);
        setRedeemcodeValid(false);
        setFolderHistory([]);
        setProducts([]);
        setInputCode('');
        console.log(redeemcodeValid);
    }

    // 实现返回上一级文件夹的功能
    const goBack = () => {
        const newFolderHistory = folderHistory.slice(0, folderHistory.length - 1);
        const newCurrentFolderId = newFolderHistory.length > 0 ? newFolderHistory[newFolderHistory.length - 1] : null;

        setFolderHistory(newFolderHistory);
        setCurrentFolderId(newCurrentFolderId);
    };


    return (
        <div className='main-container'>
            <style jsx>{`
                body, html {
                    overflow: hidden;
                }
                .logo {
                    height: 24px;
                }
                
                .content-left, .content-right {
                    padding: 20px;
                }
                .content-left {
                    margin-right: 0px;
                    // flex: 55;
                }
                .content-right {
                    margin-right: 0px;
                    // flex: 100;
                }
                .main-container {
                    background: linear-gradient(272deg, #FBF3F6 0%, #FFF5EB 51%, #FFFDE9 100%);
                    height: 100%;
                    width: 100%;
                    overflow: hidden;
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .main-container::before, .main-container::after {
                    content: "";
                    position: absolute;
                    border-radius: 50%;
                    filter: blur(var(--blur-radius));
                }
                .main-container::before {
                    --blur-radius: 6vw;
                    width: 24vw;
                    height: 24vw;
                    bottom: -12vw;
                    left: calc(5% - 12vw);
                    background: linear-gradient(190deg, #FFDCE9, #FFCC5A);
                }
                .main-container::after {
                    --blur-radius: 8vw;
                    width: 46vw;
                    height: 46vw;
                    top: -23vw;
                    right: calc(5% - 23vw);
                    background: linear-gradient(170deg, #FFDCE9, #FFE2A0);
                }

                .tutorial {
                    display: flex;
                    align-items: center;
                    color: #252525;
                    font-weight: 500;
                    font-family: 'Alibaba PuHuiTi 3.0';
                    padding-left: 16px;
                }
                .icon {
                    height: 26px;
                    margin-right: 10px;
                }
                .icon-qrcode {
                    width: auto;
                }
                .icon-slogan {
                    height: 120px;
                }
                .tutorial-button {
                    height: 100%;
                    background: white;
                    border-radius: 100px;
                    padding: 8px 16px;
                    color: #252525;
                    font-weight: 500;
                    font-family: 'Alibaba PuHuiTi 3.0';
                    border: none;
                    cursor: pointer;
                }
                .welcome-container-1 {
                    display: flex;
                    align-items: center;
                    padding: 4px 0px;
                    height: auto;
                }
                .welcome-container-2 {
                    display: flex;
                    align-items: center;
                    background: #111;
                    border-radius: 100px;
                    padding: 4px 16px;
                }
                .welcome-font {
                    font-size: 16px;
                    font-weight: bold;
                }
                .welcome-font-1 {
                    color: #fff;
                }
                .welcome-font-2 {
                    color: #111;
                    padding-left: 8px;
                }
                .social-medial-box {
                    display: flex;
                    align-items: center;
                }
                .search-box {
                    display: flex;
                    align-items: center;
                    height: 26px;
                    border-radius: 24px;
                    border: 1px solid black;
                    background-color: rgba(255, 255, 255, 0.1);
                    padding: 0px 16px;
                    width: -webkit-fill-available;
                }
                .download-progress-container {
                    position: fixed;
                    bottom: 64px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: auto; /* 设置宽度为自动 */
                    max-width: 90%; /* 可选，限制最大宽度以避免过宽 */
                    padding: 0 16px; /* 增加左右内边距以增强视觉效果 */
                    height: 36px;
                    background: white;
                    border-radius: 18px;
                    border: 1px solid #ffcf65;
                    box-shadow: 0px 0px 20px #f2e3c5;
                    display: ${downloadProgress !== null ? 'flex' : 'none'};
                    align-items: center;
                    justify-content: center;
                    font-size: 16px;
                    color: #252525;
                    white-space: nowrap; /* 防止内容换行 */
                }
            `}</style>

            <div className={`${userPageStyle.header} ${userPageStyle[layout]}`}>
                <img src="/logoHOHO.svg" alt="Logo" className="logo" />
            </div>
            <div className={`${userPageStyle.content} ${userPageStyle[layout]}`}>
                {(!redeemcodeValid && (layout === 'small' || layout === 'medium')) &&
                    <div>
                        <div style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            padding: "4px 0px",
                            height: "auto"
                        }}>
                            <div className='welcome-container-2'>
                                <Typography.Text className={'welcome-font welcome-font-1'} style={{
                                    fontSize: "12px"
                                }}>
                                    WELCOME
                                </Typography.Text>
                            </div>
                            <Typography.Text className={`welcome-font welcome-font-2 `} style={{
                                fontSize: "12px"
                            }}>
                                欢迎来到HOHO的小世界
                            </Typography.Text>
                            <Typography.Title bold className={userPageStyle.extraBoldFont} style={{
                                fontSize: '36px',
                                marginTop: '12px',
                                marginBottom: 0
                            }}>
                                和HOHO在一起
                            </Typography.Title>
                            <Typography.Title bold className={userPageStyle.extraBoldFont} style={{
                                fontSize: '36px',
                                marginTop: 0
                            }}>
                                专注生活
                            </Typography.Title>

                        </div>

                    </div>}
                <div className="content-left">
                    {redeemcodeValid ? (
                        <ProductList
                            products={products}
                            onEnterFolder={enterFolder}
                            onDownload={handleDownload}
                            onDownloadFolder={handleFolderDownload}
                            onGoBack={goBack}
                            onReInputCode={handleReInputCode}
                            canGoBack={folderHistory.length > 0}
                            isDownloading={isDownloading}
                        />
                    ) : (
                        <div className={`${userPageStyle['tutorial-container']} ${userPageStyle[layout]}`} style={{ backgroundColor: "#fff", gap: "8px" }}>
                            <div className="tutorial">
                                <input
                                    type="text"
                                    placeholder="请输入取件码"
                                    style={{ border: '#25252520', borderRadius: '8px', padding: '8px', fontSize: '16px' }}
                                    value={inputCode}
                                    onChange={(e) => setInputCode(e.target.value)}
                                />
                            </div>
                            <button
                                style={{ fontSize: '24px', fontWeight: 'bold', border: 'none', height: '100%', backgroundColor: "#00000000", padding: "0px", width: "72px" }}
                                onClick={() => {
                                    if (inputCode) {
                                        window.location.href = `/get-hoho-product/${inputCode}`;
                                    } else {
                                        Message.error('请输入取件码');
                                    }
                                }}
                            >
                                + 取件
                            </button>
                        </div>
                    )}
                    <div className={`${userPageStyle['tutorial-container']} ${userPageStyle[layout]}`}>
                        <div className="tutorial">
                            <img src="/tutorial.svg" alt="Logo" className="icon" />
                            <span>使用教程</span>
                        </div>
                        <a href="https://www.yuque.com/mercurrry/assistant_mori" style={{ height: '100%' }} target="_blank">
                            <button className="tutorial-button">立即查看</button>
                        </a>
                    </div>
                </div>
                <div className="content-right">
                    {(layout === 'large') &&
                        <div>
                            <div className='welcome-container-1'>
                                <div className='welcome-container-2'>
                                    <Typography.Text className={'welcome-font welcome-font-1'}>WELCOME</Typography.Text>
                                </div>
                                <Typography.Text className={'welcome-font welcome-font-2'}>欢迎来到HOHO的小世界</Typography.Text>
                            </div>
                            <Typography.Title bold className={userPageStyle.extraBoldFont} style={{ fontSize: '48px', marginTop: '20px' }}>向内探索✨<br style={{ margin: 0 }} />看见自己的每一次成长</Typography.Title>
                            <Divider style={{ borderBottom: ' 1px solid #111' }} />
                        </div>
                    }

                    <div style={{
                        display: 'flex',
                        flex: 0,
                        alignItems: 'center',
                        height: 'auto',
                        flexDirection: layout !== 'large' ? 'column' : 'row',
                        marginTop: layout !== 'large' ? 'auto' : '40px',
                        marginBottom: layout !== 'large' ? '36px' : '40px'
                    }}>
                        <div style={{ display: 'flex', flexDirection: 'column', gap: '12px' }}>
                            <div className='social-medial-box'>
                                <img src="/xiaohongshu.svg" alt="Logo" className="icon social-media-icon"
                                    onClick={() => window.open('https://www.xiaohongshu.com/user/profile/5e22e4260000000001003458?xhsshare=CopyLink&appuid=55b43432b7ba2207f146089b&apptime=1715087402', '_blank')}
                                    style={{ cursor: 'pointer' }} />
                                <img src="/weibo.svg" alt="Logo" className="icon social-media-icon"
                                    onClick={() => window.open('https://weibo.com/u/7325224686', '_blank')}
                                    style={{ cursor: 'pointer' }} />
                                <div className='search-box'>
                                    <Typography.Text style={{ fontSize: '16px', marginTop: '0px' }}>HOHOPLANET</Typography.Text>
                                    <IconSearch style={{ height: '16px', marginLeft: '12px' }} />
                                </div>
                            </div>
                            <div className='social-medial-box'>
                                <img src="/taobao.svg" alt="Logo" className="icon social-media-icon"
                                    onClick={() => window.open('https://shop529659390.taobao.com/?spm=pc_detail.27183998/evo365560b447257.202202.1.7c1e7dd6NCcBA2', '_blank')}
                                    style={{ cursor: 'pointer' }} />
                                <div className='search-box'>
                                    <Typography.Text style={{ fontSize: '16px', marginTop: '0px' }}>HOHO厚厚电子手账</Typography.Text>
                                    <IconSearch style={{ height: '16px', marginLeft: '12px' }} />
                                </div>
                            </div>
                            <div className='social-medial-box'>
                                遇到使用问题，可以咨询HOHO小助手哦 &gt;
                            </div>
                        </div>
                        <img src="/qrcode.png" alt="Logo" className="icon-qrcode" style={{ marginLeft: '16px', height: '100%', width: '120px', objectFit: 'contain' }} />
                    </div>
                </div>
            </div>
            <div className="download-progress-container">
                文件正在下载，请耐心等待 {downloadProgress !== null && `${downloadProgress.toFixed(2)}%`}
            </div>
        </div>
    );
}

export default RedeemProductPage;
