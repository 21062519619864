// client/src/components/ProductList.jsx
import React from 'react';
import { Button, Typography, Divider } from '@arco-design/web-react';
import { IconFolder, IconDriveFile, IconDownload, IconArrowLeft, IconUndo } from '@arco-design/web-react/icon';

const ProductList = ({ products, onEnterFolder, onDownload, onDownloadFolder, onGoBack, canGoBack, onReInputCode, isDownloading }) => {
    const productArray = Array.isArray(products) ? products : [products];
    return (
        <div className="product-list-container">
            <div style={{
                display: "flex",
                alignItems: "center",
                paddingBottom: "16px",
                borderBottom: "1px solid #e8e8e8"
            }}>
                {canGoBack ?
                    (<div className="back-button" onClick={onGoBack}>
                        <IconUndo />
                        <span>返回上一级</span>
                    </div>) : (
                        <div className="back-button" style={{
                            display: 'flex',
                            alignItems: 'center',
                            cursor: 'not-allowed',
                            color: '#ccc'
                        }}>
                            <IconUndo />
                            <span>返回上一级</span>
                        </div>)}
            </div>
            <div className="list" style={{
                overflowY: "auto"
            }}>
                {productArray.map((product) => (
                    <div key={product.id} className="list-item">
                        <div className="item-left"
                            onClick={product.is_folder ? () => onEnterFolder(product.id) : undefined}
                            style={{ cursor: product.is_folder ? 'pointer' : 'default' }}>
                            <div className="item-icon">
                                {product.is_folder ? <IconFolder /> : <IconDriveFile />}
                            </div>
                            <div className="item-name">
                                <Typography.Text ellipsis style={{ marginBottom: "0px" }}>{decodeURIComponent(product.name)}</Typography.Text>
                            </div>
                        </div>
                        <div className="item-action">
                            <Button
                                type="text"
                                icon={<IconDownload />}
                                onClick={() => onDownload(product)}
                                style={{
                                    backgroundColor: "#FFCF65",
                                    color: "#252525",
                                    borderRadius: "24px",
                                    height: "24px",
                                    width: "24px",
                                    marginTop: "8px",
                                    marginBottom: "8px",
                                    cursor: isDownloading ? 'not-allowed' : 'pointer'
                                }}
                                disabled={isDownloading}
                                title={isDownloading ? '请等待下载完成' : ''}
                            />
                        </div>
                    </div>
                ))}
            </div>
            <div className="footer">
                <Button type="primary" onClick={onReInputCode} style={{
                    backgroundColor: "#FFFFFF",
                    color: "#252525",
                    padding: "0px 24px",
                    borderColor: "#FFCF65",
                }}>
                    再次兑换
                </Button>

                <Button type="primary" onClick={() => onDownload("downloadfolder")} style={{
                    backgroundColor: "#FFCF65",

                    color: "#252525",

                    padding: "0px 24px",
                }}>
                    下载全部
                </Button>
            </div>
            <style jsx>{`
                .product-list-container {
                    background-color: white;
                    padding: 24px;
                    border-radius: 24px;
                    box-shadow: 0px 0px 16px 0 #F2E3C5;
                }
                .back-button {
                    display: flex;
                    align-items: center;
                    cursor: pointer;
                    font-weight: bold;
                }
                .back-button span {
                    margin-left: 8px;
                    font-size: 16px;
                }
                .list {
                    max-height: 500px; // 设置最大高度
                }
                .list-item {
                    display: flex;
                    align-items: center;
                    justify-content: space-between; // 确保左侧和右侧元素分开
                    padding: 8px 16px;
                }
                .item-left {
                    display: flex;
                    align-items: center;
                }
                .item-icon {
                    margin-right: 16px;
                }
                .item-name {
                    margin-right: 16px;
                }
                .item-action {
                    flex: 0 0 auto;
                }
                .footer {
                    padding-top: 16px;
                    border-top: 1px solid #e8e8e8;
                    display: flex;
                    justify-content: center;
                    gap: 12px;
                }
                .footer Button {
                    border-radius: 50px;
                    background-color: #FFCF65;
                }
            `}</style>
        </div>
    );
};

export default ProductList;