import React, { useState } from 'react';
import { Card, Form, Input, Button, Message } from '@arco-design/web-react';

function LoginForm() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        try {
            const response = await fetch('/api/admin/login', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ username, password }),
            });

            const data = await response.json();
            setIsLoading(false);

            if (data.status === 'success') {
                // 处理登录成功的逻辑，例如保存 token
                console.log('登录成功:', data.token);
                // 保存 token 到 localStorage
                localStorage.setItem('token', data.token);
                Message.success(data.message);
                window.location.href = '/admin/product-management';
            } else {
                // 处理登录失败的逻辑
                console.log('登录失败:', data.message);
                Message.error(data.message);
            }
        } catch (error) {
            setIsLoading(false);
            console.error('登录请求失败:', error);
            Message.error('登录请求失败');
        }
    };

    return (
        <div style={{ maxWidth: '350px', margin: '0 auto', marginTop: '100px' }}>
            <Card>
                <Form>
                    <Form.Item label="用户名">
                        <Input
                            placeholder="请输入用户名"
                            value={username}
                            onChange={(value) => setUsername(value)}
                            disabled={isLoading}
                        />
                    </Form.Item>
                    <Form.Item label="密码">
                        <Input.Password
                            placeholder="请输入密码"
                            value={password}
                            onChange={(value) => setPassword(value)}
                            disabled={isLoading}
                        />
                    </Form.Item>
                    <Button type="primary" htmlType="button" onClick={handleSubmit} block loading={isLoading}>
                        登录
                    </Button>
                </Form>
            </Card>
        </div>
    );
}

export default LoginForm;
