// client/src/pages/RedeemCodeManagementPage.jsx
import React, { useState, useEffect, useRef } from 'react';
import { Table, Button, Message, Modal, Input, Upload, Space, Typography } from '@arco-design/web-react';
import { IconFolder, IconDriveFile } from '@arco-design/web-react/icon';

const { Text } = Typography;

function ProductManagementPage() {
    const [products, setProducts] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState(false);
    const [redeemCodeToDelete, setRedeemCodeToDelete] = useState('');

    const [isQueryModalVisible, setIsQueryModalVisible] = useState(false);
    const [queryCode, setQueryCode] = useState('');
    const [queryResult, setQueryResult] = useState('');

    // const [isModalVisible, setIsModalVisible] = useState(false);
    // const [newName, setNewName] = useState('');
    // const [currentProduct, setCurrentProduct] = useState(null);
    // const [selectedFiles, setSelectedFiles] = useState([]);
    // const [currentFolderId, setCurrentFolderId] = useState(null); // 当前文件夹ID

    // const uploadRef = useRef();

    useEffect(() => {
        fetchProductsForSale();
    }, []);

    const fetchProductCodeStatus = async (product) => {
        const response = await fetch(`/api/codes/products/${product.id}/code-status`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
        });

        const data = await response.json();

        if (response.ok && data.status === 'success') {
            return { totalCodes: data.totalCodes, unusedCodes: data.unusedCodes };
        } else {
            console.error(`Failed to fetch code status for product ${product.id}: ${data.message}`);
            return { totalCodes: 0, unusedCodes: 0 };
        }
    };


    // 获取商品列表
    const fetchProductsForSale = async () => {
        setIsLoading(true);
        try {
            const url = `/api/products/contents-for-sale`;
            console.log(`请求URL: ${url}`);
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            console.log('请求返回的数据:', data);

            if (data.status === 'success') {
                console.log('商品列表加载成功', data.contents);
                const productsWithCodeStatus = await Promise.all(data.contents.map(async (product) => {
                    const codeStatus = await fetchProductCodeStatus(product);
                    return { ...product, ...codeStatus };
                }));
                setProducts(productsWithCodeStatus);
            } else {
                console.error('获取商品列表出错:', data.message);
                Message.error(data.message);
            }
        } catch (error) {
            console.error('获取商品列表失败:', error);
            Message.error('获取商品列表失败');
        }
        setIsLoading(false);
    };

    const deleteRedeemCode = async () => {
        if (!redeemCodeToDelete) {
            Message.warning('请输入兑换码');
            return;
        }
        setIsDeleteModalVisible(false);
        try {

            const response = await fetch(`/api/codes/products/00/code/${redeemCodeToDelete}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            console.log('请求返回的数据:', data);

            if (data.status === 'success') {
                console.log('删除成功', data.contents);
                Message.success('删除成功');

            } else {
                console.error('删除失败:', data.message);
                Message.error(data.message);
            }
        } catch (error) {
            console.error('删除失败:', error);
            Message.error('删除失败');
        }
    };
    const deleteAllRedeemCodes = async (productId) => {
        try {

            const response = await fetch(`/api/codes/products/${productId}/code`, {
                method: 'DELETE',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();
            console.log('请求返回的数据:', data);

            if (data.status === 'success') {
                Message.success('删除成功');
                console.log('全部删除成功', data.contents);
            } else {
                console.error('删除失败:', data.message);
                Message.error(data.message);
            }
        } catch (error) {
            console.error('删除失败:', error);
            Message.error('删除失败');
        }
    };

    async function createRedeemCode(productId) {
        const response = await fetch(`/api/codes/products/${productId}/code`, {
            method: 'POST', // 使用 POST 方法创建资源
            headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
                'Content-Type': 'application/json',
            },
            // 无需提供兑换码，因为兑换码是由服务器生成的
        });

        if (!response.ok) {
            throw new Error(`Error: ${response.status} - ${response.statusText}`);
        }

        const data = await response.json();

        if (data.status === 'success' && data.newCodes && data.newCodes.length > 0) {
            let codes = '';
            for (let i = 0; i < data.newCodes.length; i++) {
                codes += data.newCodes[i];
                if (i < data.newCodes.length - 1) {
                    codes += '\n'; // 在最后一个代码后不添加换行符
                }
            }
            try {
                await navigator.clipboard.writeText(codes);
                Message.success('所有兑换码已复制到剪贴板');
                console.log('所有兑换码已复制到剪贴板:', codes);
            } catch (err) {
                console.error('无法复制兑换码到剪贴板:', err);
                Message.error('兑换码获取失败');
            }
        } else {
            Message.error('未能创建兑换码');
            console.error('未能创建兑换码');
        }

        return data;
    }


    // 查询兑换码的函数
    const fetchRedeemCodeUsageCount = async () => {
        if (!queryCode) {
            Message.warning('请输入兑换码');
            return;
        }
        try {
            const response = await fetch(`/api/codes/${queryCode}/status`, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`,
                },
            });
            const data = await response.json();

            if (data.status === 'success') {
                setQueryResult(`兑换码使用次数: ${data.usageCount}`);
                Message.success(`查询成功: ${data.usageCount}`);
            } else {
                console.error('查询失败:', data.message);
                Message.error(data.message);
            }
        } catch (error) {
            console.error('查询失败:', error);
            Message.error('查询失败');
        }
    };

    // 表格列定义，包括进入文件夹和下载的操作
    const columns = [
        {
            title: '名称',
            dataIndex: 'name',
            render: (text, record) => (
                <>
                    {record.is_folder ? <IconFolder /> : <IconDriveFile />} {decodeURIComponent(text)}
                </>
            ),
        },
        {
            title: '操作',
            render: (_, record) => (
                <Space>
                    <Button type="primary" onClick={() => createRedeemCode(record.id)}>
                        生成800条兑换码
                    </Button>
                    <Button status="danger" onClick={() => deleteAllRedeemCodes(record.id)}>
                        删除全部兑换码
                    </Button>
                </Space>
            ),
        },
        {
            title: '总兑换码数',
            dataIndex: 'totalCodes',
        },
        {
            title: '未使用兑换码数',
            dataIndex: 'unusedCodes',
        },
    ];

    return (
        <div>
            <Space
                direction="vertical"
                size={16}
                style={{ width: '100%' }}
            >

                <Space>
                    <Button type="primary" onClick={() => fetchProductsForSale()}>
                        刷新列表
                    </Button>
                    <Button type="primary" onClick={() => setIsQueryModalVisible(true)}>
                        查询兑换码
                    </Button>
                    <Button status="danger" onClick={() => setIsDeleteModalVisible(true)}>
                        删除兑换码
                    </Button>


                </Space>
                <Table loading={isLoading} columns={columns} data={products} rowKey="id" />
            </Space>
            <Modal
                title="删除兑换码"
                visible={isDeleteModalVisible}
                onOk={deleteRedeemCode}
                onCancel={() => setIsDeleteModalVisible(false)}
            >
                <Input
                    placeholder="请输入要删除的兑换码"
                    value={redeemCodeToDelete}
                    onChange={setRedeemCodeToDelete}
                />
            </Modal>
                        {/* 查询兑换码模态框 */}
            <Modal
                title="查询兑换码"
                visible={isQueryModalVisible}
                onOk={fetchRedeemCodeUsageCount}
                onCancel={() => setIsQueryModalVisible(false)}
            >
                <Input
                    placeholder="请输入要查询的兑换码"
                    value={queryCode}
                    onChange={setQueryCode}
                />
                {queryResult && <Text>{queryResult}</Text>}
            </Modal>

        </div>
    );
}

export default ProductManagementPage;

